import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TelegramLoginWidgetComponent } from './telegram-login/telegram-login-widget.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'hb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [MatCardModule, TelegramLoginWidgetComponent]
})
export class LoginComponent implements OnInit {
  returnUrl = '';

  constructor(private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.returnUrl = this.activeRoute.snapshot.queryParams.returnUrl || '/home';
  }
}
