<div class="card-container">
  <mat-card>
    <mat-card-subtitle class="title" i18n>Log In / Sign Up</mat-card-subtitle>
    <mat-card-content class="content">
      <p i18n>Welcome to HobbyTS ⭐️ Let's find events and teammates for you.</p>
      <p i18n>Sign in with Telegram and don't forget to confirm access to our HobbytsBot.</p>
      <p class="login-widget">
        <hb-telegram-login-widget [returnUrl]="returnUrl"></hb-telegram-login-widget>
      </p>
    </mat-card-content>
  </mat-card>
</div>

<p class="security-text info-text" i18n>
  By proceeding, you agree to Hobbyt's
  <a href="https://hobbytostar.com/privacy-policy" target="_blank">Privacy Policy</a> and
  <a href="https://hobbytostar.com/terms-conditions" target="_blank">Terms of Service</a>
</p>
